var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-form-model',{attrs:{"colon":false,"model":_vm.queryForm,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"考试名称"},model:{value:(_vm.queryForm.examName),callback:function ($$v) {_vm.$set(_vm.queryForm, "examName", $$v)},expression:"queryForm.examName"}})],1),_c('a-form-model-item',[_c('a-cascader',{attrs:{"changeOnSelect":true,"placeholder":"归属目录","options":_vm.useableTree,"fieldNames":{
                label: 'name',
                value: 'id',
                children: 'children',
              }},model:{value:(_vm.queryForm.type),callback:function ($$v) {_vm.$set(_vm.queryForm, "type", $$v)},expression:"queryForm.type"}})],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","dataSource":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      }},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"考试名称","data-index":"examName"}}),_c('a-table-column',{attrs:{"title":"归属目录","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_vm._v(_vm._s(_vm.getType(text)))]}}])}),_c('a-table-column',{attrs:{"title":"答题人","data-index":"userName","align":"center"}}),_c('a-table-column',{attrs:{"title":"部门","data-index":"deptName","align":"center"}}),_c('a-table-column',{attrs:{"title":"答题时间","data-index":"createAt","align":"center"}}),_c('a-table-column',{attrs:{"title":"得分","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',{staticStyle:{"color":"#1890ff"}},[_vm._v(_vm._s(text.score))])]}}])}),_c('a-table-column',{attrs:{"align":"center","title":"操作","width":"80px"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(_vm.$getPermission(_vm.$route.path + '/review'))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.review(text)}}},[_vm._v("阅卷")]):_vm._e()]}}])})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }