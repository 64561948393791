<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-form-model :colon="false" :model="queryForm" layout="inline" @keyup.enter.native="query">
        <a-form-model-item>
          <a-input v-model="queryForm.examName" placeholder="考试名称" style="width: 150px"></a-input>
        </a-form-model-item>

        <a-form-model-item>
          <a-cascader
            :changeOnSelect="true"
            placeholder="归属目录"
            v-model="queryForm.type"
            :options="useableTree"
            :fieldNames="{
                  label: 'name',
                  value: 'id',
                  children: 'children',
                }"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button @click="query" type="primary">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <Padding />

      <a-table
        bordered
        :dataSource="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="考试名称" data-index="examName"></a-table-column>

        <a-table-column title="归属目录" align="center">
          <template slot-scope="text">{{ getType(text) }}</template>
        </a-table-column>

        <a-table-column title="答题人" data-index="userName" align="center"></a-table-column>
        <a-table-column title="部门" data-index="deptName" align="center"></a-table-column>
        <a-table-column title="答题时间" data-index="createAt" align="center"></a-table-column>
        <a-table-column title="得分" align="center">
          <template slot-scope="text">
            <span style="color: #1890ff">{{ text.score }}</span>
          </template>
        </a-table-column>

        <a-table-column align="center" title="操作" width="80px">
          <template slot-scope="text">
            <a
              href="#"
              v-if="$getPermission($route.path + '/review')"
              @click.prevent="review(text)"
            >阅卷</a>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchUserAnswerList } from "@/api/exam";
import study from "@/mixins/study";

export default {
  mixins: [watermark, study],

  data() {
    return {
      queryForm: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, queryForm } = this;

      this.loading = true;
      fetchUserAnswerList({
        pageNum: current,
        pageSize,
        ...queryForm,
        type:
          Array.isArray(queryForm.type) && queryForm.type.length > 0
            ? queryForm.type[queryForm.type.length - 1]
            : undefined
      })
        .then(res => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.queryForm = {};
      this.current = 1;
      this.getList();
    },

    review(text) {
      this.$router.push(
        `${this.$route.path}/review?id=${text.id}&examId=${text.examId}&examName=${text.examName}&userId=${text.userId}&userName=${text.userName}&deptId=${text.deptId}&deptName=${text.deptName}`
      );
    },

    getType(text) {
      let types = [];
      this.findFatherNames(types, text.type);
      return types.join("/");
    }
  }
};
</script>